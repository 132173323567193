import React from "react";

const Button = ({
  title,
  category = "1",
  color = "light",
  className = "",
  onClick = () => {},
  disabled = false,
  loading,
  type = "button",
  ref,
}) => {
  const categoryType = {
    "1": "py-2 px-4 rounded-[27px]",
    "2": "py-1 px-3 rounded leading-5",
    "3": "py-2 px-6 w-full rounded-[27px]",
  };
  const scheme = {
    light: disabled
      ? "bg-grey-850 text-placeholder-grey border-0"
      : "text-black bg-white border border-grey-200",
    dark: disabled
      ? "bg-grey-850 text-placeholder-grey border-0"
      : "text-grey bg-black border border-black",
    dark2: disabled
      ? "bg-disabled-black text-disabled-white border-0"
      : "text-grey bg-black border border-black",
    authLight: disabled
      ? "bg-grey-850 text-placeholder-grey"
      : "bg-grey border border-grey-200 text-black",
  };

  return (
    <button
      ref={ref}
      type={type}
      tabIndex={disabled ? -1 : 0}
      onClick={disabled ? () => {} : onClick}
      className={`whitespace-nowrap flex items-center justify-center gap-x-1 min-w-fit h-fit font-semibold ${
        categoryType[category]
      } ${scheme[color]} ${className} ${disabled ? "cursor-not-allowed" : ""} ${
        loading ? "cursor-wait" : ""
      }`}
    >
    {/* //   {loading && <CircularProgress color="inherit" size={18} />} */}
      <span className="-tracking-[0.2px]">{title}</span>
    </button>
  );
};

export default Button;
