import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

// Helpers
import { emailPattern } from "../../utils";
import { axiosInstance } from "../../baseAxios";

// Components
import Button from "../../components/internal/button";
import Input, { TextArea } from "../../components/internal/input";
import AuthContext from "../../context/auth";

const NewUserFollowupMail = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = async (data) => {
    await axiosInstance
      .post("/internal/email/verification", data)
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.message);
      });
    reset();
  };
  return (
    <AuthContext.Consumer>
       {({ isAuthenticated }) => { return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="px-4 md:px-11 w-full max-w-[720px] mx-auto"
      >
        <div className="flex flex-col gap-y-10 w-full md:max-w-[800px] mb-9 mt-12 md:mt-16">
          <Input
            name="email"
            type="email"
            label="Recipient's Email"
            {...{ register, errors }}
            rules={{ required: true, pattern: emailPattern }}
            labelStyle="text-desc-grey"
            placeholder="name@company.com"
          />
          <Input
            name="firstName"
            label="Recipient's First Name"
            {...{ register, errors }}
            rules={{ required: true }}
            labelStyle="text-desc-grey"
            placeholder="Recipient's First Name"
          />
          <TextArea
            label="Message body"
            placeholder="Message body"
            name="body"
            {...{ register, errors }}
            rules={{ required: true }}
            rows={6}
          />
        </div>

        <Button
          title="Continue"
          color="dark"
          disabled={!isValid}
          className={`text-base px-4 py-[7px] rounded-[27px] max-w-fit ${
            !isValid ? "text-disabled-white" : ""
          }`}
          type="submit"
        />
      </form>)}}
    </AuthContext.Consumer>
  );
};

export default NewUserFollowupMail;
