import React from "react";

// Helpers
import { getClassNames } from "../../../utils";

export const TextArea = ({
  name,
  defaultValue,
  placeholder,
  label,
  register,
  rules,
  errors,
  disabled,
  className,
  category = "regular",
  labelStyle = "",
  rows,
}) => {
  const categoryType = {
    regular: getClassNames(
      "border border-stroke outline-none bg-white4 placeholder-placeholder-grey text-body-black",
      "text-base -tracking-[0.4px] font-semibold p-3 md:p-4 mt-1 leading-5 rounded focus:border-desc-grey w-full",
      className
    ),
  };
  const labelStyleType = {
    regular: "text-desc-grey text-base leading-5 font-semibold",
  };
  return (
    <div className="w-full">
      {label && (
        <label
          htmlFor={name}
          className={getClassNames(
            labelStyle ? labelStyle : labelStyleType[category]
          )}
        >
          {label}
        </label>
      )}
      <textarea
        rows={rows}
        disabled={disabled}
        defaultValue={defaultValue}
        {...register(name, { ...rules })}
        className={getClassNames(categoryType[category])}
        placeholder={placeholder}
      />
      {errors && errors[name] && (
        <span className="text-red-alert text-sm">
          * {errors[name]?.message}
        </span>
      )}
    </div>
  );
};

const Input = ({
  name,
  type = "text",
  defaultValue,
  value,
  placeholder,
  label,
  register,
  rules,
  errors,
  disabled,
  className,
  category = "auth",
  labelStyle,
}) => {
  const categoryType = {
    auth: getClassNames(
      "bg-grey-850 border border-stroke4 placeholder-placeholder-grey text-body-black text-xl leading-6 md:text-2xl",
      "md:leading-7 font-semibold py-3 md:py-4 rounded px-2 mt-1 w-full focus:outline-none focus:ring-0",
      className
    ),
    regular: getClassNames(
      "border border-stroke outline-none bg-white4 placeholder-placeholder-grey text-body-black",
      "text-base -tracking-[0.4px] font-semibold p-3 md:p-4 leading-5 rounded w-full focus:border-b-desc-grey",
      className
    ),
  };
  const labelStyleType = {
    regular: "text-desc-grey text-base leading-5 font-semibold",
    auth: "text-grey text-lg leading-[1.375rem] font-semibold",
  };
  const labelDefault = labelStyle ? "" : category;

  return (
    <div className="w-full">
      {label && (
        <label
          htmlFor={name}
          className={getClassNames(labelStyleType[labelDefault], labelStyle)}
        >
          {label}
        </label>
      )}
      <input
        type={type}
        disabled={disabled}
        defaultValue={defaultValue}
        value={value}
        {...register(name, { ...rules })}
        className={getClassNames(categoryType[category])}
        placeholder={placeholder}
      />
      {errors && errors[name] && (
        <span className="text-red-alert text-sm">
          * {errors[name]?.message}
        </span>
      )}
    </div>
  );
};

export default Input;
